<template>
  <div class="page-wrapper">
    
    <header class="main-header">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a :href="$t('site_hiver_2023.al_entrepot_link')" target="_blank" class="cta red">{{ $t('site_hiver_2023.buy_tickets') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone4.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a :href="$t('site_hiver_2023.al_entrepot_link')" target="_blank" class="cta red">{{ $t('site_hiver_2023.buy_tickets') }}</a>
      </div>
    </header>

    <section class="hero hiver_2023_printemps_entrepot spacing-bottom big_winter">
      <div class="img-mobile"></div>
      <div class="deal-wrap smaller" data-inview="slideLeft" data-delay="100">
        <div class="top">
          <img src="@/assets/img/curve-green.svg" alt="Curve">
          <div class="data"><h2 class="big-title" v-html="$t('site.slogan')"></h2></div>
        </div>
        <div class="bottom">
          <div class="deal solo">
            <h2 class="small-title" v-html="$t('site_hiver_2023.al_entrepot_hero')"></h2>
            <a :href="$t('site_hiver_2023.al_entrepot_link')" target="_blank" class="cta blanc-bleu">{{ $t('site_hiver_2023.buy_tickets') }}</a>
            <a href="#offre-wrap" class="regular-text" @click.prevent="showdealpanel">{{ $t('site.show-details') }}</a>
          </div>
        </div>
      </div>
    </section>

    <section class="activities_wrap spacing-top no_padding_bottom hiver" id="activities">

      <img src="@/assets/img/curve-bleu-big.svg" alt="curve" class="curve" />
      <div class="intro" data-inview="fadeInUp" data-delay="200">
        <h2 class="medium-title" v-html="$t('site_hiver_2023.domaine_title')"></h2>
        <p class="regular-text">
          {{ $t('site_hiver_2023.domaine_desc') }}
        </p>
      </div>

      <!-- CARROUSEL ACTIVITY -->
      <div class="activities_list">
        <div v-for="(item, i) in $t('dink_hiver_2023_domaine')" :key="i" class="activity" data-inview="fadeInUp" :data-delay="3 + 1 + '00'">
          <a v-if="item.video" :href="item.video" class="glightbox img">
            <img src="@/assets/img/play.svg" alt="Play" class="play" />
            <div class="overlay"></div>
            <img :src="item.img" class="activity-img" alt="Montagne Tremblant" />
          </a>
          <div v-else-if="!item.video" class="img no_click">
            <div class="overlay"></div>
            <img :src="item.img" class="activity-img" alt="Montagne Tremblant" />
          </div>
          <div class="data">
            <div v-html="item.titre"></div>
            <a
              href="#"
              class="cta blanc-bleu"
              v-html="item.cta"
              @click.prevent="
                toggleDynamicSidePanel(
                  true,
                  $t('dink_hiver_2023_domaine').filter((i) => calendarActiveFilter === 'Tous' || calendarActiveFilter === 'All' || i.filter === calendarActiveFilter),
                  i
                )
              "
              ></a
            >
          </div>
        </div>
      </div>

    </section>

    <section class="scroll_img">
      <h2 class="medium-title separator small-mb" id="secteurs" data-inview="fadeInUp" data-delay="200">{{ $t('site_hiver_2023.apres_ski_title') }}</h2>
      <a :href="$t('site_hiver_2023.ski_magazine_link')" target="_blank" class="regular-text center_link" data-inview="fadeInUp" data-delay="250">{{ $t('site_hiver_2023.ski_magazine') }}</a>

      <div class="imgs-wrap">
        <div class="scroll-track">
          <div class="auto-track">
            <div class="img-wrap">
              <div class="img big">
                <img src="@/assets/img/apres-ski/dink/big-1.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img small">
                <img src="@/assets/img/apres-ski/dink/small-1.jpg" alt="Après ski Tremblant">
                <img src="@/assets/img/apres-ski/dink/small-2.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img big">
                <img src="@/assets/img/apres-ski/dink/big-2.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img big">
                <img src="@/assets/img/apres-ski/dink/big-3.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img small">
                <img src="@/assets/img/apres-ski/dink/small-3.jpg" alt="Après ski Tremblant">
                <img src="@/assets/img/apres-ski/dink/small-4.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img big">
                <img src="@/assets/img/apres-ski/dink/big-1.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img big">
                <img src="@/assets/img/apres-ski/dink/big-2.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img small">
                <img src="@/assets/img/apres-ski/dink/small-2.jpg" alt="Après ski Tremblant">
                <img src="@/assets/img/apres-ski/dink/small-3.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img big">
                <img src="@/assets/img/apres-ski/dink/big-3.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img big">
                <img src="@/assets/img/apres-ski/dink/big-1.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img small">
                <img src="@/assets/img/apres-ski/dink/small-1.jpg" alt="Après ski Tremblant">
                <img src="@/assets/img/apres-ski/dink/small-2.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img big">
                <img src="@/assets/img/apres-ski/dink/big-2.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img big">
                <img src="@/assets/img/apres-ski/dink/big-3.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img small">
                <img src="@/assets/img/apres-ski/dink/small-3.jpg" alt="Après ski Tremblant">
                <img src="@/assets/img/apres-ski/dink/small-4.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img big">
                <img src="@/assets/img/apres-ski/dink/big-1.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img big">
                <img src="@/assets/img/apres-ski/dink/big-2.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img small">
                <img src="@/assets/img/apres-ski/dink/small-2.jpg" alt="Après ski Tremblant">
                <img src="@/assets/img/apres-ski/dink/small-3.jpg" alt="Après ski Tremblant">
              </div>
              <div class="img big">
                <img src="@/assets/img/apres-ski/dink/big-3.jpg" alt="Après ski Tremblant">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bloc_wrap hiver">
      <img src="@/assets/img/curve-vert-big.svg" class="curve" alt="Courbe" />
      <div class="bloc" data-inview="fadeInUp" data-delay="200">
        <div class="img">
          <img src="@/assets/img/cross-sell-hebergement-printemps.jpg" alt="Tremblant hébergement" />
        </div>
        <div class="data">
          <h3 class="small-title" v-html="$t('site_hiver_2023.al_lodging_title')"></h3>
          <a href="#" class="cta blanc-bleu politique-panel" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
        </div>
      </div>
      <div class="bloc" data-inview="fadeInUp" data-delay="300">
        <div class="img">
          <img src="@/assets/img/ecoresponsabilite-hiver.jpg" alt="" />
        </div>
        <div class="data">
          <h3 class="small-title" v-html="$t('site_ete_2022.eco_small_title')"></h3>
          <a href="#" class="cta blanc-bleu eco-panel" @click.prevent="showecopanel">{{ $t('site_ete_2022.more') }}</a>
        </div>
      </div>
    </section>

    <news-letter color="hiver"></news-letter>

    <footer-bar></footer-bar>

    <!--Offer Popup right bottom-->
    <div class="deal-popup hiver" :class="{ active: dealpop, force: dealpopforce }">
      <div class="open_box" @click.prevent="toggledealpop">
        <img src="@/assets/img/arrow-left.svg" alt="Arrow" class="open"/>
        <p class="v_txt">{{ $t('site_ete_2022.show_offer') }}</p>
      </div>
      <img src="@/assets/img/arrow-right.svg" alt="Arrow" class="close" @click.prevent="toggledealpop" />
      <p class="small-title" v-html="$t('site_hiver_2023.al_entrepot_pop')"></p>
      <a href="#" class="cta blanc-bleu" @click.prevent="showdealpanel">{{ $t('site_ete_2022.bh_lodging_pop_cta') }}</a>
    </div>

    <!--Popup offre-->
    <div class="overlay-side-panel" :class="{ show: dealpanel }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidedealpanel" />
      <div class="side-panel deal">
        <div class="img hiver_2023_printemps_entrepot"></div>
        <div class="data hiver" :class="{ actif: toggle }">
          <div v-html="$t('site_hiver_2023.al_entrepot_data')"></div>
          <a :href="$t('site_hiver_2023.al_entrepot_link')" target="_blank" class="cta blanc-rouge">{{ $t('site.achetez-short') }}</a>
          <br />
          <a v-if="$route.meta.lang === 'fr'" href="/hiver/2223/printemps/terms-entrepot/" class="regular-text modalite">{{ $t('site.modalites') }}</a>
          <a v-else-if="$route.meta.lang === 'en'" href="/en/hiver/2223/printemps/terms-entrepot/" class="regular-text modalite">{{
            $t('site.modalites')
          }}</a>
        </div>
      </div>
      <div class="opacity" @click.prevent="hidedealpanel"></div>
    </div>
    
    <!-- Carte Privilège -->
    <div class="overlay-side-panel" :class="{ show: sidepanel }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidesidepanel">

      <div class="side-panel" v-if="privileges && privileges[(sidepanel - 1)]">
        <div class="img dink" :style="'background-image: url(' + privileges[(sidepanel - 1)].image + ')'" @click.stop>
          <div class="arrow-wrap prev">
            <img src="@/assets/img/prev.svg" alt="prev" @click.prevent.stop="goToPrevPanel()">
          </div>
          <div class="arrow-wrap next">
            <img src="@/assets/img/next.svg" alt="next" @click.prevent.stop="goToNextPanel()">
          </div>
        </div>
        <div class="data" v-html="privileges[(sidepanel - 1)].details"></div>
      </div>
      <div class="opacity" @click.prevent="hidesidepanel"></div>
    </div>

    <!-- Dynamic Side Panel -->
    <div class="overlay-side-panel dynamic-side-panel" :class="{ show: dynamicPanelIsOpen }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="toggleDynamicSidePanel(false)" />
      <div class="side-panel">
        <div
          v-if="dynamicPanelIsOpen && dynamicPanelItems && dynamicPanelItems.length"
          class="img"
          :style="'background-image:url(' + dynamicPanelItems[dynamicPanelPos].img + ')'"
        >
          <div v-if="dynamicPanelItems.length > 1" class="arrow-wrap prev" @click.prevent.stop="dynamicSidePanelGoTo('prev')">
            <img src="@/assets/img/prev.svg" alt="prev" />
          </div>
          <div v-if="dynamicPanelItems.length > 1" class="arrow-wrap next" @click.prevent.stop="dynamicSidePanelGoTo('next')">
            <img src="@/assets/img/next.svg" alt="next" />
          </div>
        </div>
        <div class="data">
          <span v-if="dynamicPanelIsOpen && dynamicPanelItems && dynamicPanelItems.length" v-html="dynamicPanelItems[dynamicPanelPos].data"></span>
        </div>
      </div>
      <div class="opacity" @click.prevent="toggleDynamicSidePanel(false)"></div>
    </div>

    <!--Popup Écoresponsabilité-->
    <div class="overlay-side-panel" :class="{ show: ecopanel }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hideecopanel" />
      <div class="side-panel">
        <div class="img eco_hiver"></div>
        <div class="data">
          <h3 class="small-title" v-html="$t('site_ete_2022.eco_small_title')"></h3>
          <p class="regular-text">{{ $t('site_ete_2022.eco_intro_txt') }}</p>
          <p class="regular-text bold">{{ $t('site_ete_2022.eco_saviez_vous') }}</p>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_1')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_2')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_3')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_4')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_5')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_6')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_7')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_8')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_9')"></p>
          </div>
        </div>
      </div>
      <div class="opacity" @click.prevent="hideecopanel"></div>
    </div>

    <!--Popup Rando Alpine-->
    <div class="overlay-side-panel" :class="{ show: politiquepanel }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidepolitiquepanel" />
      <div class="side-panel">
        <div class="img rando_alpine"></div>
        <div class="data">
          <h3 class="small-title" v-html="$t('site_hiver_2023.rando_title')"></h3>
          <div v-html="$t('site_hiver_2023.rando_data')"></div>
        </div>
      </div>
      <div class="opacity" @click.prevent="hidepolitiquepanel"></div>
    </div>

    <!--Warranty panel-->
    <div class="overlay-side-panel" :class="{ show: warrantypanel }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidewarrantypanel">
      <div class="side-panel">
        <div class="img warranty"></div>
        <div class="data" v-html="$t('site.garantie-data')"></div>
      </div>
      <div class="opacity" @click.prevent="hidewarrantypanel"></div>
    </div>

    <!--Popup formulaire-->
    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform" />
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          :action="$t('site_hiver_2023.bh_lodging_link')"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              v-model="time1"
              name="arrivaldate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'arrivaldate', required: 'required' }"
              format="MM/DD/YYYY"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              v-model="time2"
              name="departuredate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'departuredate', required: 'required' }"
              format="MM/DD/YYYY"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input name="Adult" type="number" value="2" min="1" required />
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input name="Child" type="number" value="0" required />
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')" />
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { isNil } from 'lodash'
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'



import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

gsap.registerPlugin(ScrollTrigger)

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: this.$t(`site_hiver_2023.al_entrepot_meta_title`)
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content:  this.$t(`site_hiver_2023.al_entrepot_meta_title`) },
        {
          name: 'description',
          content:  this.$t(`site_hiver_2023.al_entrepot_meta_desc`),
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content:  this.$t(`site_hiver_2023.al_entrepot_meta_title`) },
        { name: 'twitter:card', content: 'Tremblant' },
        {
          n: 'twitter:description',
          c:  this.$t(`site_hiver_2023.al_entrepot_meta_desc`)
        },
        // Facebook / Open Graph
        { property: 'og:title', content:  this.$t(`site_hiver_2023.al_entrepot_meta_title`) },
        { property: 'og:site_hiver_2023_name', content:  'Tremblant' },
        {
          property: 'og:description',
          content:  this.$t(`site_hiver_2023.al_entrepot_meta_desc`)
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://promo.tremblant.ca/vue-app-static-img/hiver-2023/bighit/tonik-dink.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://promo.tremblant.ca/vue-app-static-img/hiver-2023/bighit/tonik-dink.jpg',
        },
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      warrantypanel: false,
      privileges: [],
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null,
      dealpop: false,
      dealpopforce: false,
      dealpanel: false,
      dynamicPanelIsOpen: false,
      dynamicPanelPos: 0,
      dynamicPanelItems: [],
      ecopanel: false,
      politiquepanel: false,
    }
  },
  watch: {
    $route: {
      handler($route) {
        if (!isNil($route)) {
          if ($route.hash) {
            setTimeout(() => {
              if ($route.hash === '#privileges') {
                const $target = document.querySelector('#ancre-privileges')
                $target.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                return;
              }
              if ($route.hash === '#tab1') {
                this.dealpanel = true
              }
              if ($route.hash === '#tab2') {
                this.dealpanel = true
                this.toggle = false
              }
              const $target = document.querySelector('#acnre-offre-wrap')
              $target.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
            }, 755)
          }
        }
      },
      immediate: true
    }
  },
  computed: mapState('app', ['appTitle']),

  created() {
    window.addEventListener('scroll', () => {
      this.actiondealpop()
    })
  },

  methods: {
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    toggledealpop() {
      if(this.dealpopforce === false) {
        this.dealpopforce = true
      } else {
        this.dealpopforce = false
      }
    },
    toggleDynamicSidePanel(value, items = [], pos = 0) {
      this.dynamicPanelIsOpen = value
      this.dynamicPanelItems = items
      this.dynamicPanelPos = pos
    },
    dynamicSidePanelGoTo(action) {
      if (action === 'prev') {
        if (this.dynamicPanelPos === 0) {
          this.dynamicPanelPos = this.dynamicPanelItems.length - 1
        } else {
          this.dynamicPanelPos -= 1
        }
      } else if (this.dynamicPanelPos === this.dynamicPanelItems.length - 1) {
        this.dynamicPanelPos = 0
      } else {
        this.dynamicPanelPos += 1
      }
    },
    showecopanel() {
      if (this.ecopanel) {
        this.ecopanel = false
      } else {
        this.ecopanel = true
      }
    },
    hideecopanel() {
      this.ecopanel = false
    },
    actiondealpop() {
      const st = document.documentElement.scrollTop
      const value = document.querySelector('#activities').offsetTop

      if (st >= value) {
        this.dealpop = true
      } else {
        if(this.dealpopforce) {
          this.dealpopforce = false
        }
        this.dealpop = false
      }
    },
    showdealpanel() {
      if (this.dealpanel) {
        this.dealpanel = false
      } else {
        this.dealpanel = true
      }
    },
    hidedealpanel() {
      this.dealpanel = false
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel(index) {
      this.sidepanel = index
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    showpolitiquepanel() {
      if (this.politiquepanel) {
        this.politiquepanel = false
      } else {
        this.politiquepanel = true
      }
    },
    hidepolitiquepanel() {
      this.politiquepanel = false
    },
    goToPrevPanel() {
      if (this.sidepanel <= 1) {
        this.sidepanel = this.privileges.length
        return
      }
      this.sidepanel -= 1
    },
    goToNextPanel() {

      if (this.sidepanel >= this.privileges.length) {
        this.sidepanel = 1
        return
      }

      this.sidepanel += 1
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    showwarrantypanel() {
        if(this.warrantypanel) {
          this.warrantypanel = false
        } else {
          this.warrantypanel = true
        }
    },
    hidewarrantypanel() {
      this.warrantypanel = false
    }
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }

    this.privileges = this.$t('privileges')

    setTimeout(function() {
      gsap.to('.scroll-track', {
        scrollTrigger: {
          trigger: '.scroll-track',
          start: 'top bottom',
          end: 'bottom top',
          scrub: 1,
          markers: false
        },
        x: -1000,
        ease: 'none'
      })
    }, 1275)

  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
